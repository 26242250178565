* {
  box-sizing: border-box;
  font-family: 'PingFangSC-Regular','NotoSansSC-Regular',"Avenir", Helvetica, Arial, sans-serif;
}

html body {
  background: #000;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.flex-order-1 {
  order: 1;
}

.flex-order-2 {
  order: 2;
}

.flex-order-3 {
  order: 3;
}

@font-face {
  font-family: 'NotoSansSC-Regular';
  src: local('NotoSansSC-Bold'), url(./fonts/NotoSansSC-Bold.otf) format('opentype');
}

@font-face {
  font-family: 'NotoSansSC-Regular';
  src: local('NotoSansSC-Regular'), url(./fonts/NotoSansSC-Regular.otf) format('opentype');
}

@font-face {
  font-family: 'NotoSansSC-Light';
  src: local('NotoSansSC-Light'), url(./fonts/NotoSansSC-Light.otf) format('opentype');
}

@font-face {
  font-family: 'NotoSansSC-Thin';
  src: local('NotoSansSC-Thin'), url(./fonts/NotoSansSC-Thin.otf) format('opentype');
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
