
.big-subtitle {
  font-family: 'PingFangSC-Semibold','NotoSansSC-Semibold', monospace;
  font-size: 54px;
  color: #333333;
  letter-spacing: 0;
  line-height: 74px;
  margin: 0;
}
.medium-subtitle {
  font-family: 'PingFangSC-Regular', 'NotoSansSC-Regular', monospace;
  font-size: 44px;
  color: #333333;
  letter-spacing: 2px;
  text-align: left;
  line-height: 64px;
  margin: 0;
}

.small-subtitle {
  font-family: 'PingFangSC-Regular', 'NotoSansSC-Regular', monospace;
  font-size: 18px;
  color: #1D98A9;
  letter-spacing: 1px;
  margin: 0;
}

.regular-text {
  font-family: 'PingFangSC-Regular', 'NotoSansSC-Regular', monospace;
  font-size: 26px;
  color: #333333;
  letter-spacing: 1px;
  margin: 0;
}

.thin-text {
  font-family: 'PingFangSC-Thin', 'NotoSansSC-Thin', monospace;
  max-width: 440px;
  font-size: 18px;
  color: #333333;
  letter-spacing: 0;
  text-align: left;
  line-height: 34px;
  margin: 0;
}

.light-text {
  font-family: 'PingFangSC-Light', 'NotoSansSC-Light', monospace;
  font-size: 12px;
  letter-spacing: 0;
  margin: 0;
}

.small-text {
  font-size: 14px;
  line-height: 24px;
  margin: 0;
}

.underline {
  border-bottom: 2px solid currentColor;
}

.blue-underline {
  position: relative;
}

.blue-underline::after {
  display: block;
  content: '';
  width: 60px;
  height: 2px;
  background: #1D98A9;
  position: absolute;
  bottom: 0;
  left: 0;
}

.yellow-underline {
  position: relative;
}

.yellow-underline::after {
  display: block;
  content: '';
  width: 20px;
  height: 4px;
  background: #FFDD62;
  position: absolute;
  bottom: 0;
  left: 0;
}

.light-color {
  color: #fff;
}

.blue-text {
  color: #1D98A9;
}

@media screen and (max-width: 992px) {
  .big-subtitle {
    font-size: 40px;
    letter-spacing: 2px;
    line-height: 56px;
  }
  .medium-subtitle {
    font-size: 30px;
    letter-spacing: 0;
    line-height: 34px;
  }
  .small-subtitle {
    font-size: 18px;
  }
  .regular-text {
    font-size: 16px;
    line-height: 30px;
  }
  .thin-text {
    font-size: 16px;
    line-height: 30px;
  }
}
