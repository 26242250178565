
.container-fluid.visual-grid-container {
  padding-left: 32px;
  padding-right: 32px;
}

.light-background {
  background: #fff;
}

.container-fluid.visual-grid-container-fixed {
  position: fixed;
  -webkit-backface-visibility: hidden;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 9;
  padding-left: 32px;
  padding-right: 32px;
  pointer-events: none;
}

.visual-grid-wrapper {
  height: 100%;
  width: 100%;
}

@media (min-width: 992px) {
  .row.visual-grid {
    left: -52px;
    width: calc(100% + 104px);
    padding-left: 52px;
    padding-right: 52px;
  }
}

.visual-grid {
  position: absolute;
  top: 0;
  height: 100%;
  left: -32px;
  width: calc(100% + 64px);
  padding-left: 32px;
  padding-right: 32px;
  overflow-x: hidden;
}
.row.visual-grid,
.row.visual-grid-wrapper {
  margin: 0;
}

.visual-grid__vertical{
  border-right: 1px solid #acb2d8;
  opacity: .4;
  padding: 0;
}

.visual-grid__vertical:first-child {
   border-left: 1px solid #acb2d8;
 }

.visual-grid__vertical:last-child {
  border: 0;
}

.section {
  margin-top: 80px;
  padding-top: 50px;
  padding-bottom: 50px;
}

@media (min-width: 768px) {
  .visual-grid__vertical:first-child {
    border-left: 0;
  }
}

