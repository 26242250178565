

 .slide .carousel-indicators li.active {
  background-color: #2a2a2b;
}

.slide .carousel-indicators li {
  background-color: #808081;
  border-radius: 50%;
  height: 6px;
  width: 6px;
}.

.carousel-indicators{
   margin-bottom: 50px;
}

 .slide .carousel-control-prev,
 .slide .carousel-control-next {
   position: absolute;
   bottom: 20px;
   list-style: none;
   width: 20px;
   height: 20px;
   top: inherit;
 }

 @media screen and (max-width: 768px) {
   .slide .carousel-control-prev,
   .slide .carousel-control-next {
     display: none;
   }
 }

 .slide .carousel-control-prev {
   width: 20px;
   left: 0;
 }

 .slide .carousel-control-next {
   width: 20px;
   right: 0;
 }

 .slide .carousel-control-prev-icon {
   background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23808081' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E");
 }

 .slide .carousel-control-next-icon {
   background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23808081' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E");
 }


